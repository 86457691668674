import React from 'react';
import './App.css';
import CategoryQuestions from './CategoryQuestions';
import Response from './Response';
import SearchResult from './SearchResult'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/:id" element={<CategoryQuestions />} />
        <Route path="/response/:id" element={<Response />} />
        <Route path="/search-result/:query" element={ <SearchResult  /> } />
      </Routes>
    </Router>
  );
}

export default App;
