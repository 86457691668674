import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Search from './Search';

function CategoryQuestions() {
  const { id } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [showAllQuestions, setShowAllQuestions] = useState(false);
  const hasFetchedCategoryQuestions = useRef(false);
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  const state = JSON.parse(sessionStorage.getItem('categoryState')) || {};
  const { categoryName, categoryIconUrl, userId } = state;

  const fetchCategoryQuestions = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/category-question/${id}`, {
        credentials: 'include',
      });
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const data = await res.json();
      setSubCategories(data.questions);
    } catch (error) {
      console.error('Error fetching category questions:', error);
    }
  };

  const handleShowMore = () => {
    setShowAllQuestions(!showAllQuestions);
  };

  const handleQuestionClick = (question) => {
    const redirectUrl = `/response/${question.id}`;
    const state = {
      questionName: question.question,
      categoryId: id,
      categoryName: categoryName,
      categoryIconUrl: categoryIconUrl,
      userId: userId
    };
    sessionStorage.setItem('questionState', JSON.stringify(state));
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    // const getCookie = (name) => {
    //   const cookieString = document.cookie.split('; ').find(row => row.startsWith(`${name}=`));
    //   return cookieString ? cookieString.split('=')[1] : null;
    // };
  
    // const userId = getCookie('userId');
    // if (!userId) {
    //   window.location.href = 'https://www.inito.com';
    //   return;
    // }

    if (!hasFetchedCategoryQuestions.current) {
      fetchCategoryQuestions();
      hasFetchedCategoryQuestions.current = true;
    }
  }, [id]);

  return (
    <div className="questions">
      <div className="question_area">
        <Search source="Category" userId={userId}/>

        <div className="title">
          <img
            src={`${baseUrl}${categoryIconUrl}`}
            alt='icon'
            className="category-icon"
          />
          <div className="text">{categoryName}</div>
        </div>

        <div className="data">
          {subCategories.map((subCategory) => (
            <React.Fragment key={subCategory.name}>
              <div className='sub_category'>{subCategory.name}</div>
              <ul>
                {subCategory.questions.length > 0 && subCategory.questions.slice(0, showAllQuestions ? subCategory.questions.length : 2).map((question) => (
                  <li key={question.id} className="question" onClick={() => handleQuestionClick(question)}>
                    <div className="left">
                      <div className="text">{question.question}</div>
                    </div>
                    <div className="right">
                      <img src="/images/right-arrow.png" className="icon" alt="right arrow" />
                    </div>
                  </li>
                ))}
              </ul>
              {subCategory.questions.length > 2 && (
                <button onClick={handleShowMore} className="show_button">
                  {showAllQuestions ? (
                    <div className="show_text">
                      Show Less<img src="/images/top_arrow.png" alt="Up arrow" className="arrow_icon" />
                    </div>
                  ) : (
                    <div className="show_text">
                      Show More<img src="/images/bottom_arrow.png" alt="Down arrow" className="arrow_icon" />
                    </div>
                  )}
                </button>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CategoryQuestions;
