import React, { useState } from 'react';
import logAmplitudeEvent from './utils/amplitude'; 

function Search({ source, userId, query = '' }) {
  const [text, setText] = useState(query);
  const [response, setResponse] = useState([]);
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  const fetchData = async (inputText) => {
    try {
      const res = await fetch(`${baseUrl}/api/search/${encodeURIComponent(inputText.trim())}`, {
        credentials: 'include',
      });
      const data = await res.json();
      
      if (Array.isArray(data.descriptions) && data.descriptions.length !== 0) {
        setResponse(data.descriptions);
      } else {
        setResponse([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setText(newValue); 

    if (newValue.trim() !== '') {
      fetchData(newValue);
      logAmplitudeEvent('HelpQuickSearchLoaded', {
        SearchTerm: event.target.value,
        NoofResults: response.length,
        Source: "Home",
        SourceType: source
      }, userId);
    } else {
      setResponse([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (text.trim() === '') {
      return;
    }
    await fetchData(text);

    const redirectUrl = `/search-result/${encodeURIComponent(text.trim())}`;
    const state = {
      data: response,
      source: source,
      userId: userId
    };
    sessionStorage.setItem('searchResults', JSON.stringify(state));
    window.location.href = redirectUrl;
  };

  const handleQuestionClick = (question, category, rank) => {
    logAmplitudeEvent('HelpQuickSearchCTAClicked', {
      SearchTerm: text,
      NoofResults: response.length,
      Source: "Home",
      SourceType: source,
      CTA: question.question,
      ResultRank: rank + 1
    }, userId);
    const redirectUrl = `/response/${question.id}`;
    const state = {
      questionName: question.question,
      categoryId: category.id,
      categoryName: category.name,
      categoryIconUrl: category.icon.url,
      userId: userId
    };
    sessionStorage.setItem('questionState', JSON.stringify(state));
    window.location.href = redirectUrl;
  };

  return (
    <div className="search">
      <div className="search_box">
        <form onSubmit={handleSubmit}>
          <label>
            <input
              type="text"
              value={text}
              onChange={handleChange}
              placeholder="What would you like to find?"
            />
          </label>
          <button type="submit" disabled={text.trim() === ''}>
            <img src="/images/search_icon.png" className='icon' alt="Search"/>
          </button>
        </form>
      </div>

      {response && response.length > 0 && response.some(item => item.found === 'question') && (
        <div className="overlay">
          <div className="info">
            <p>Results you may be interested in</p>
            <ul className="search-results">
              {response.map((item, index) => (
                item.found === 'question' && (
                  <li key={item.description.id} className="search-result" onClick={() => handleQuestionClick(item.description.answer.question, item.description.answer.question.sub_category.category, index)}>
                    {item.description.answer.question.question}
                  </li>
                )
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Search;
