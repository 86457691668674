import { init, logEvent, setUserId } from '@amplitude/analytics-browser';

/**
 * @param {string} eventName
 * @param {Object} eventProperties
 * @param {string} userId
 */
const logAmplitudeEvent = (eventName, eventProperties, userId) => {
  if (userId) {
    setUserId(userId);
  } else {
    console.error('Invalid userId provided for Amplitude');
    return;
  }

  const eventData = prepareEventData(eventName, eventProperties, userId);
  try {
    logEvent(eventName, eventData);
    console.log("event logged")
  } catch (error) {
    console.error(`Error logging event ${eventName}`, error);
  }
};

/**
 * @param {string} eventName
 * @param {Object} eventProperties
 * @param {string} userId
 * @returns {Object}
 */
const prepareEventData = (eventName, eventProperties, userId) => {
  let eventDetails = {};
  const timestamp = new Date().toISOString();

  switch (eventName) {
    case 'HelpPageLoaded':
      eventDetails = {
        PageType: eventProperties.PageType,
        Category: eventProperties.Category || '',
        PageTitle: eventProperties.PageTitle || '',
      };
      break;
    case 'HelpCTAClicked':
      eventDetails = {
        CTA: eventProperties.CTA,
        PageType: eventProperties.PageType,
        Category: eventProperties.Category || '',
        PageTitle: eventProperties.PageTitle || '',
        CTAType: eventProperties.CTAType || '',
      };
      break;
    case 'HelpSearchPageLoaded':
      eventDetails = {
        SearchTerm: eventProperties.SearchTerm,
        IsResultGiven: eventProperties.IsResultGiven,
        NoofResults: eventProperties.NoofResults,
        NoofPages: eventProperties.NoofPages,
        PageNo: eventProperties.PageNo,
        Source: eventProperties.Source,
        SourceType: eventProperties.SourceType,
      };
      break;
    case 'HelpSearchCTAClicked':
      eventDetails = {
        SearchTerm: eventProperties.SearchTerm,
        IsResultGiven: eventProperties.IsResultGiven,
        NoofResults: eventProperties.NoofResults,
        NoofPages: eventProperties.NoofPages,
        PageNo: eventProperties.PageNo,
        Source: eventProperties.Source,
        SourceType: eventProperties.SourceType,
        CTA: eventProperties.CTA,
        ResultRank: eventProperties.ResultRank || '',
        CTAType: eventProperties.CTAType || '',
      };
      break;
    case 'HelpArticlePageLoaded':
      eventDetails = {
        Title: eventProperties.Title,
        Category: eventProperties.Category,
        Subcategory: eventProperties.Subcategory,
        Source: eventProperties.Source,
      };
      break;
    case 'HelpArticleCTAClicked':
      eventDetails = {
        Title: eventProperties.Title,
        Category: eventProperties.Category,
        Subcategory: eventProperties.Subcategory,
        Source: eventProperties.Source,
        CTA: eventProperties.CTA,
        CTAType: eventProperties.CTAType || '',
        Comment: eventProperties.Comment || '',
      };
      break;
    case 'HelpQuickSearchLoaded':
      eventDetails = {
        SearchTerm: eventProperties.SearchTerm,
        NoofResults: eventProperties.NoofResults,
        Source: eventProperties.Source,
        SourceType: eventProperties.SourceType,
      };
      break;
    case 'HelpQuickSearchCTAClicked':
      eventDetails = {
        SearchTerm: eventProperties.SearchTerm,
        NoofResults: eventProperties.NoofResults,
        Source: eventProperties.Source,
        SourceType: eventProperties.SourceType,
        CTA: eventProperties.CTA,
        ResultRank: eventProperties.ResultRank,
      };
      break;
    case 'ContactUsBottomSheetLoaded':
      eventDetails = {
        Title: eventProperties.Title,
        Body: eventProperties.Body,
        ScreenDetail: eventProperties.ScreenDetail,
      };
      break;
    case 'ContactUsBottomSheetCTAClicked':
      eventDetails = {
        Title: eventProperties.Title,
        Body: eventProperties.Body,
        ScreenDetail: eventProperties.ScreenDetail,
        CTA: eventProperties.CTA,
      };
      break;
    default:
      eventDetails = eventProperties;
      break;
  }

  return {
    event_properties: eventDetails,
    user_id: userId,
    time: new Date().getTime(),
    insert_id: `${eventName}-${userId}-${timestamp}`,
  };
};

export default logAmplitudeEvent;