import React from 'react';
import logAmplitudeEvent from './utils/amplitude'; 

function Footer({ source, userId, title, category }) {
  function openContactUsDeepLink() {
    window.location.href = 'inito:/inito.com/help/contact_us';
  }

  function openFacebookCommunity() {
    window.open('https://www.facebook.com/groups/initofertility', '_blank')
    logAmplitudeEvent('HelpCTAClicked', {
      CTA: "Ask in Community",
      PageType: source,
      Category: category,
      PageTitle: title
    }, userId);
  }

  return (
    <div className="footer">
      <div className="top-text">Didn’t find the answer?</div>
      <div className='community' onClick={openFacebookCommunity}>
        <div className='title'>
          <div className="left">
            <img src="/images/facebook_icon.png" className="icon" alt="icon" />Ask in Community
          </div>
          <div className="right">
            <img src="/images/right-arrow.png" className="icon" alt="right arrow" />
          </div>
        </div>
        <p>
          You can ask any question in our Inito woman community in Facebook and get help
        </p>
      </div>

      <div className='community' onClick={openContactUsDeepLink}>
        <div className='title'>
          <div className="left">
            <img src="/images/contact_us.png" className="icon" alt="icon" />Contact us
          </div>
          <div className="right">
            <img src="/images/right-arrow.png" className="icon" alt="right arrow" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
