import React, { useState, useEffect, useRef } from 'react';
import Footer from './Footer';
import Search from './Search';
import logAmplitudeEvent from './utils/amplitude';

function Home() {
  const [categories, setCategories] = useState([]);
  const hasFetchedCategories = useRef(false);
  const [userId, setUserId] = useState('')

  useEffect(() => {
    const id = document.cookie
      .split('; ')
      .find(row => row.startsWith('user_id'))
      ?.split('=')[1];
    setUserId(id)
    logAmplitudeEvent('HelpPageLoaded', {
      PageType: "Home"
    }, userId);
    if (!hasFetchedCategories.current) {
      fetchCategories();
      hasFetchedCategories.current = true;
    }
  }, []);

  const fetchCategories = async () => {
    const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    try {
      const res = await fetch(`${baseUrl}/api/categories?populate=*`, {
        credentials: 'include',
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleCategoryClick = (categoryId, categoryName, categoryIconUrl) => {
    logAmplitudeEvent('HelpCTAClicked', {
      CTA: categoryName,
      PageType: "Help",
      CTAType: "Category"
    }, userId);
    const state = {
      categoryName: categoryName,
      categoryIconUrl: categoryIconUrl,
      userId: userId
    };
    sessionStorage.setItem('categoryState', JSON.stringify(state));
    window.location.href = `/category/${categoryId}`;
  };  

  return (
    <div className="home">
      <header className="header">
        <div className="left">
          <img src="/images/inito_logo.png" className="logo" alt="logo" />
        </div>
      </header>

      <div className="question_area">
        <div className="top-text">I need help with...</div>
        <Search source="Home" userId={userId}/>

        <div className="category_area">
          {categories.data && (
            <div className="categories">
              <ul>
                {categories.data.map((category) => (
                  <li key={category.id} className="category" onClick={() => handleCategoryClick(category.id, category.attributes.name, category.attributes.icon.data.attributes.url)}>
                    <div className="left">
                      {category.attributes.icon && category.attributes.icon.data && (
                        <img
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${category.attributes.icon.data.attributes.url}`}
                          alt={category.attributes.icon.data.attributes.alternativeText || 'icon'}
                          className="category-icon"
                        />
                      )}
                      <div className="text">{category.attributes.name}</div>
                    </div>
                    <div className="right">
                      <img src="/images/right-arrow.png" className="icon" alt="right arrow" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <Footer source="Home" userId={userId} title='' category='' />
      </div>
    </div>
  );
}

export default Home;
