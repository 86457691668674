import React, { useState, useCallback, useEffect, useRef } from 'react';
import Footer from './Footer';
import Search from './Search';
import { useParams, useLocation } from 'react-router-dom';
import logAmplitudeEvent from './utils/amplitude'; 

function Response() {
  const { id } = useParams();
  const state = JSON.parse(sessionStorage.getItem('questionState')) || {};
  const [feedbackText, setFeedbackText] = useState('');
  const [answer, setAnswer] = useState({});
  const [mergedData, setMergedData] = useState([]);
  const [activeFeedback, setActiveFeedback] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const { questionName, categoryId, categoryName, categoryIconUrl, userId } = state || {};
  const hasFetchedQuestionResponse = useRef(false);
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  const fetchQuestionResponse = useCallback(async () => {
    try {
      const res = await fetch(`${baseUrl}/api/question-response/${id}`, {
        credentials: 'include',
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      setAnswer(data);
    } catch (error) {
      console.error('Error fetching question response:', error);
    }
  }, [id]);

  const mergeAndOrderData = useCallback(() => {
    const descriptions = answer?.descriptions || [];
    const images = answer?.images || [];
    const merged = [...descriptions, ...images].sort((a, b) => a.order - b.order);
    setMergedData(merged);
  }, [answer]);

  const handleFeedback = useCallback(async (isHelpful) => {
    try {
      await fetch(`${baseUrl}/api/isHelpful/${id}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ helpful: isHelpful }),
      });
      setActiveFeedback(isHelpful ? 'up' : 'down');
      logAmplitudeEvent('HelpArticleCTAClicked', {
        Title: questionName,
        Category: categoryName,
        Subcategory: categoryName,
        Source: "Category",
        CTA: isHelpful ? "HelpfulEnabled" : "NotHelpfulEnabled"
      }, userId);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  }, [id]);

  const handleChangeFeedback = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmitFeedback = async (event) => {
    event.preventDefault();
    try {
      await fetch(`${baseUrl}/api/feedback/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ text: feedbackText }),
      });
      setFeedbackText('');
      setFeedbackSubmitted(true);
      logAmplitudeEvent('HelpArticleCTAClicked', {
        Title: questionName,
        Category: categoryName,
        Subcategory: categoryName,
        Source: "Category",
        CTA: "Post Comment",
        Comment: feedbackText
      }, userId);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await handleSubmitFeedback(event);
    }
  };

  const handleFacebookClick = () => {
    window.location.href = `fb://share?text=${encodeURIComponent(`${questionName} https://api.inito.com/app/links/help/response/${id}`)}`
    logAmplitudeEvent('HelpArticleCTAClicked', {
      Title: questionName,
      Category: categoryName,
      Subcategory: categoryName,
      Source: "Category",
      CTA: "Facebook",
      CTAType: "Share"
    }, userId);
  }

  const handleWhatsappClick = () => {
    window.location.href = `https://wa.me/?text=${encodeURIComponent(`${questionName} https://api.inito.com/app/links/help/response/${id}`)}`
    logAmplitudeEvent('HelpArticleCTAClicked', {
      Title: questionName,
      Category: categoryName,
      Subcategory: categoryName,
      Source: "Category",
      CTA: "WhatsApp",
      CTAType: "Share"
    }, userId);
  }

  const handleShareClick = async() => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: `https://api.inito.com/app/links/help/response/${id}`
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.error('Sharing not supported on this browser');
    }
    logAmplitudeEvent('HelpArticleCTAClicked', {
      Title: questionName,
      Category: categoryName,
      Subcategory: categoryName,
      Source: "Category",
      CTA: "Share",
      CTAType: "Share"
    }, userId);
  }

  useEffect(() => {
    if (!hasFetchedQuestionResponse.current) {
      fetchQuestionResponse();
      hasFetchedQuestionResponse.current = true;
    }
  }, [id, fetchQuestionResponse]);

  useEffect(() => {
    mergeAndOrderData();
  }, [mergeAndOrderData, answer]);

  useEffect(() => {
    logAmplitudeEvent('HelpArticlePageLoaded', {
      Title: questionName,
      Category: categoryName,
      Subcategory: categoryName,
      Source: "Category"
    }, userId);
  })

  const handleNavigate = (path, state) => {
    sessionStorage.setItem('navigationState', JSON.stringify(state));
    window.location.href = path;
  };

  return (
    <div className="response">
      <div className="response_area">
        <div className='breadcrumb'>
          <span onClick={() => handleNavigate('/', null)} className="breadcrumb-link">
            <img src="/images/left_arrow.png" className="close icon" alt="logo" />Home
          </span>
          {categoryId && (
            <span onClick={() => handleNavigate(`/category/${categoryId}`, { categoryName, categoryIconUrl })} className="breadcrumb-link">
              <img src="/images/left_arrow.png" className="close icon" alt="logo" />{categoryName}
            </span>
          )}
        </div>
        <Search source="Article" userId={userId}/>

        <div className="info">
          <h3>{questionName}</h3>
          <div>
            {mergedData.map((item) => (
              <div key={item.order} className="item">
                <div className="text">
                  {item.description && (
                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                  )}
                </div>
                <div className="image">
                  {item.image && (
                    <img src={`${baseUrl}${item.image.url}`} alt={item.image.name} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="helpful">
          <div className="title">Was this helpful?</div>
          <div className="icons">
            <button
              className={`up_icon button ${activeFeedback === 'up' ? 'active-up' : ''}`}
              onClick={() => handleFeedback(true)}
            >
              <img src="/images/thumbs-up.png" className="close icon" alt="logo" /> Yes
            </button>
            <button
              className={`down_icon button ${activeFeedback === 'down' ? 'active-down' : ''}`}
              onClick={() => handleFeedback(false)}
            >
              <img src="/images/thumbs-down.png" className="close icon" alt="logo" /> No
            </button>
          </div>

          <div className="share">
            <div className="left">Share</div>
            <div className="right">
              <img
                src="/images/Facebook.png"
                className="close icon"
                alt="logo"
                onClick={handleFacebookClick}
              />
              {/* <img
                src="/images/Linkedin.png"
                className="close icon"
                alt="logo"
                onClick={() => window.location.href = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`}
              />
              <img
                src="/images/Twitter.png"
                className="close icon"
                alt="logo"
                onClick={() => window.location.href = `https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`}
              />
              <img
                src="/images/Pinterest.png"
                className="close icon"
                alt="logo"
                onClick={() => window.location.href = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}`}
              /> */}
               <img
                src="/images/whatsapp.png"
                className="close icon"
                alt="logo"
                onClick={handleWhatsappClick}
              />
              <img
                src="/images/share.png"
                className="close icon"
                alt="logo"
                onClick={handleShareClick}
              />
            </div>
          </div>
        </div>

        <div className="feedback">
          <div className="title">Feedback</div>
          <form onSubmit={handleSubmitFeedback}>
            <label>
              <textarea
                type="text"
                value={feedbackText}
                onChange={handleChangeFeedback}
                onKeyDown={handleKeyDown}
                placeholder="Share your thoughts"
              />
            </label>
          </form>
          <div className='success_feedback'> 
            {feedbackSubmitted && (
              <div className="thank-you-message">
                Thank you for your feedback!
              </div>
            )}
          </div>
        </div>

        <Footer source="Home" userId={userId} title={questionName} category={categoryName} />
      </div>
    </div>
  );
}

export default Response;
