import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Search from './Search';
import Footer from './Footer';
import logAmplitudeEvent from './utils/amplitude'; 

function SearchResult() {
  const { query } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10;
  const state = JSON.parse(sessionStorage.getItem('searchResults')) || {};
  const { data, source, userId } = state || {}

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        logAmplitudeEvent('HelpSearchPageLoaded', {
          SearchTerm: query,
          IsResultGiven: data.length === 0 ? false : true,
          NoofResults: data.length,
          NoofPages: Math.ceil(data.length / resultsPerPage),
          PageNo: currentPage,
          source: "Search",
          SourceType: source
        }, userId);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchSearchResults();
  }, [query]);

  const handleCategoryClick = (question, category, rank) => {
    logAmplitudeEvent('HelpSearchCTAClicked', {
      SearchTerm: query,
      IsResultGiven: data.length === 0 ? false : true,
      NoofResults: data.length,
      NoofPages: Math.ceil(data.length / resultsPerPage),
      PageNo: currentPage,
      source: "Search",
      SourceType: source,
      CTA: question.question,
      ResultRank: rank,
      CTAType: "Article"
    }, userId);
    const redirectUrl = `/response/${question.id}`;
    const state = {
      questionName: question.question,
      categoryId: category.id,
      categoryName: category.name,
      categoryIconUrl: category.icon.url,
      userId: userId
    };
    sessionStorage.setItem('questionState', JSON.stringify(state));
    window.location.href = redirectUrl;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(data.length / resultsPerPage);
  const paginatedData = data.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

  return (
    <div className='home search_result'>
      <header className="header">
        <div className="left">
          <img src="/images/inito_logo.png" className="logo" alt="logo" />
        </div>
      </header>
      <div className='info'>
        {data.length > 0 ? (
          <div className='count'>{data.length > 1 ? `${data.length} results` : '1 result'} found</div>
        ) : (
          <div className='count'>
            <Search query={query} />
            <p className='title'>No results found for: {query}</p>
            <p className='subtitle'>Try searching another keyword</p>
            <Footer />
          </div>
        )}

        <div className='responses'>
          {paginatedData.length > 0 ? (
            paginatedData.map((result, i) => (
              <div className='search' key={result.description.id}>
                <div className='category'>{result.description.answer.question.sub_category.category.name}, {result.description.answer.question.sub_category.name}</div>
                <div className='question'>
                  <div className="left">
                    <div className="text">{result.description.answer.question.question}</div>
                  </div>
                  <div className="right">
                    <img src="/images/right-arrow.png" className="icon" alt="right arrow" onClick={() => handleCategoryClick(result.description.answer.question, result.description.answer.question.sub_category.category, i + 1)} />
                  </div>
                </div>
                <div className='response'>
                  {result.highlightedSentences.map((sentence, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: sentence }} />
                  ))}
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>

        {totalPages > 1 && (
          <div className="pagination">
            <div className='left'>
              {Array.from({ length: totalPages }, (_, index) => (
                <div key={index + 1} className={currentPage === index + 1 ? 'active cover' : 'cover'}>
                  <button onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </button>
                </div>
              ))}
            </div>
            <div className='right' onClick={() => handlePageChange(currentPage + 1)}>
              <button disabled={currentPage === totalPages}>Next</button>
              <img src="/images/right_icon_pagination.png" className={currentPage === totalPages ? 'disabled' : ''} alt="right arrow" />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default SearchResult;
